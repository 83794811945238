import './style.css'
import { createApp } from 'vue'
import { createMemoryHistory, createRouter } from 'vue-router'
import { DatocmsImagePlugin } from "vue-datocms";
import { createHead } from "@vueuse/head"
import App from "./App.vue";

// Routes
import About from "../pages/About.vue"
const routes = [
  { path: '/', component: About},
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

const app = createApp(App)

// Components
import Header from "../components/Header.vue"
app.component('Header', Header)
import Banner from "../components/Banner.vue"
app.component('Banner', Banner)
import Footer from "../components/Footer.vue"
app.component('Footer', Footer)
import Carousel from "../components/Carousel.vue"
app.component('Carousel', Carousel)

const head = createHead()
app.use(head)
app.use(DatocmsImagePlugin);
app.use(router)
app.mount('#app')
