<template>
    <div id="default-carousel" class="relative w-full" data-carousel="slide">
        <div class="relative h-96 overflow-hidden rounded-lg">
            <div v-for="(img, index) in urls" :key="index" class="absolute inset-0 flex items-center justify-center"
                data-carousel-item :data-modal-target="'image-modal_carousel' + index"
                :data-modal-toggle="'image-modal_carousel' + index">
                <img :src="img.url" class="absolute block w-full h-full object-cover" alt="...">
            </div>
        </div>
        <button type="button"
            class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev>
            <span
                class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M5 1 1 5l4 4" />
                </svg>
                <span class="sr-only">Previous</span>
            </span>
        </button>
        <button type="button"
            class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next>
            <span
                class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 9 4-4-4-4" />
                </svg>
                <span class="sr-only">Next</span>
            </span>
        </button>
    </div>
    <div v-for="(img, index) in urls" :key="index">
        <div :id="'image-modal_carousel' + index" tabindex="-1" aria-hidden="true"
            class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full">
            <img :src="img.url" alt="Full Screen Image" class="absolute p-5 block max-w-full max-h-full object-contain">
            <button type="button" class="text-white-500 bg-red-500 hover:bg-gray-200 hover:text-gray-900 m-2 rounded-lg text-sm w-8 h-8 ms-auto dark:hover:bg-gray-600 dark:hover:text-white
                top-0 end-0 absolute inline-flex justify-center items-center"
                :data-modal-hide="'image-modal_carousel' + index">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only">Close Fullscreen</span>
            </button>
        </div>
    </div>
</template>




<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

defineProps({
    urls: { type: Array, required: true },
})

// initialize components based on data attribute selectors
onMounted(() => {
    initFlowbite();
})
</script>